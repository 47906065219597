<template>
    <td>
        <div class="table-data-header">{{ dataHeader }}</div>
        <span v-html="data" />
        <tooltip v-if="showTooltip" :tooltip-text="tooltipText" />
    </td>
</template>

<script>
import Tooltip from '@/components/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        dataHeader: {
            type: String,
            required: true
        },
        data: {
            type: String,
            required: true
        },
        showTooltip: {
            type: Boolean,
            required: false,
            default: false
        },
        tooltipText: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>