<template>
    <div 
        v-if="$validateText(tooltipText)"
        v-tooltip="{
            content: tooltipText,
            trigger: 'manual',
            show: show
        }" 
        class="tooltip-wrapper"
        @mouseenter="onEnter()"
        @click="toggle()"
        @mouseleave="onLeave()"    
    >
        <slot>
            <i class="fa-regular fa-info-circle icon" />  
        </slot>
    </div>
</template>

<script>
const breakpoint = 900;
export default {
    props: {
        tooltipText: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            show: false
        };
    },
    methods: {
        onEnter() {
            if (window.innerWidth > breakpoint) {
                this.show = true;
            }
        },
        onLeave() {
            this.show = false;
        },
        toggle() {
            this.show = ! this.show;
        },
    }
};
</script>

<style lang="scss" scoped>
    .tooltip-wrapper {
        display: inline-flex;
        margin: 0 0.25rem;
    }
</style>
