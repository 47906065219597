<template>
    <Section
        width="sm"
        class="big-list"
    >
        <div 
            v-if="$validateText(slice.primary.text)"
            class="rich-text"
            :class="{'border-bottom': !slice.primary.show_toplist}"
            v-html="$prismic.asHtml(slice.primary.text)"
        />
        <top-list 
            v-if="slice.primary.show_toplist"
            :items="items"
            :slice-id="formatId(slice.id)"
        />
        <big-list-item 
            v-for="(item, index) in items"
            :id="`bigListItem${formatId(slice.id) + index}`"
            :key="item.card.id"
            :item="item"
            :index="index"
            :data-type="slice.primary.data_type"
        />
    </Section>
</template>

<script>
import { pick } from 'lodash';
export default {
    props: {
        slice: {
            type: Object,
            required: true
        },
    },
    computed: {
        items() {
            return this.slice.items.filter(item => this.$validateContentRelationship(item.card))
                .map(item => {
                    const card = this.$store.state.cards[item.card.id];
                    return {
                        card: card,
                        h3: this.$validateText(item.h3) ? item.h3 : card.data.title,
                        model: new this.$models.CreditCard(card),
                        tableData: Object.values(this.getTableData(card)),
                        pros: this.getPros(card, item),
                        teaser: item.teaser,
                        toplistText: item.toplist_text,
                        topListTitle: this.$validateText(item.toplist_text) ? `${card.data.title}:` : card.data.title
                    };
                });
        }
    },
    methods: {
        getPros(card, item) {
            if (this.$validateText(item.pros)) {
                return item.pros.map(pro => [pro]);
            } 
            return card.data.pros.map(pro => pro.pro);
        },
        formatId(id) {
            return id.replace('$', '-');
        },
        getMobilePaymentsString (model) {
            const paymentMethods = [model.hasApplePay && 'Apple Pay', model.hasGooglePay && 'Google Pay', model.hasSamsungPay && 'Samsung Pay'];
            const formattedPaymentMethods = paymentMethods.filter(method => method).join(', ');
            return formattedPaymentMethods.length ? formattedPaymentMethods :'-';
        },
        getTableData(card) {
            const model = new this.$models.CreditCard(card);
            if (model.isDebit) {
                return this.tableData(
                    [
                        'yearlyCostString',
                        'withdrawalFee',
                        'currencyExchangeFee',
                        'mobilePaymentMethods'
                    ],
                    model
                );
            }
            else if (this.$isSweden() || this.$isNorway()) {
                return this.tableData(
                    [
                        'yearlyCostString',
                        'interestString',
                        'effectiveInterestString',
                        'maxCredit',
                        'interestFreeDays'
                    ],
                    model
                );
            }
            return this.tableData(
                [
                    'interestString',
                    'effectiveInterestString',
                    'yearlyCostString',
                    'maxCredit'
                ],
                model
            );
        },
        tableData(keys, model) {
            const tableData = {
                interestString: {
                    title: this.$translate('rate', 'Ränta'),
                    value: model.interestString
                },
                effectiveInterestString: {
                    title: this.$translate('effective_rate', 'Effektiv ränta'),
                    value: model.effectiveInterestString
                },
                yearlyCostString: {
                    title: this.$translate('yearly_fee', 'Årsavgift'),
                    value: model.yearlyCostString
                },
                maxCredit: {
                    title: this.$translate('max_credit', 'Max kredit'),
                    value: model.getMaxCreditString(this.$translate('no_max_credit', 'Ingen kreditgräns'))
                },
                interestFreeDays: {
                    title: this.$translate('interest_free_days', 'Räntefria dagar'),
                    value: model.getInterestFreeDaysString(
                        this.$translate('core_models_rate_free_days', '{days} dagar')
                    )
                },
                currencyExchangeFee: {
                    title: this.$translate('currency_surcharge', 'Valutapåslag'),
                    value: model.getCurrencyExchangeFeeString()
                },
                withdrawalFee: {
                    title: this.$translate('withdrawal_fee', 'Uttagsavgift'),
                    value: model.getWithdrawalFeeString()
                },
                mobilePaymentMethods: {
                    title: this.$translate('mobile_payments', 'Mobila betalningsmetoder'),
                    value: this.getMobilePaymentsString(model)
                }
            };
            return pick(tableData, keys);
        }
    }
};
</script>
<style lang="scss" scoped>
    .border-bottom {
        @include border(bottom);
        padding-bottom: 1rem;
    }
</style>