<template>
    <Section 
        v-if="author"
        class="author-content"
        :width="sliceWidth || 'sm'"
    >
        <div 
            v-if="$validateText(slice.primary.text)"
            class="rich-text rich-text-header"
            v-html="$prismic.asHtml(slice.primary.text)"
        />
        <div class="wrapper">
            <div class="author-section">
                <PrismicImage 
                    :img="author.data.image"
                    :alt="author.data.name"
                    w="140"
                    class="author-section-image"
                />
                <div class="author-section-details">
                    <nuxt-link 
                        :to="$prismic.linkResolver(author)"
                        class="author-section-details-name"    
                    >
                        {{ author.data.name }}
                    </nuxt-link>
                    <a 
                        v-if="author.data.email" 
                        :href="`mailto:${author.data.email}`"
                        class="author-section-details-email"
                    >
                        <i class="fas fa-envelope" />
                        <span>{{ author.data.email }}</span>
                    </a>
                </div>
            </div>
            <div 
                v-if="$validateText(slice.primary.content)"
                class="rich-text" 
                v-html="$prismic.asHtml(slice.primary.content)"    
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        sliceWidth() {
            return this.slice.primary.slice_width;
        },
        author() {
            return this.$store.state.authors[this.slice.primary.author.id];
        }
    }
};
</script>

<style lang="scss" scoped>
    .author-content::v-deep {
        .rich-text-header {
            @include spacing(margin, 6, bottom);
        }
        .rich-text > :first-child {
            margin-top: 0;
        }
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        @include device(pad) {
            flex-direction: row;
        }

        .author-section {
            display: flex;
            flex-shrink: 0;
            flex-wrap: wrap;
            align-items: center;
            @include device(pad) {
                flex-direction: column;
            }

            &-image {
                width: 90px;
                flex-shrink: 1;
                @include device(pad) {
                    width: unset;
                }
            }
            &-details {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                font-family: $fontText;
                &-name {
                    font-weight: bold;
                    color: #677788;
                    @include device(pad) {
                        font-size: 14px;
                        text-align: center;
                    }
                    &:hover {
                        color: $pink;
                        opacity: 1;
                    }
                }
                &-email {
                    display: block;
                    color: $black;
                    font-weight: bold;
                    font-size: 14px;
                    &:hover {
                        color: $pink;
                    }
                    @include device(pad) {
                        display: none;
                    }

                    i {
                        color: $pink;
                        margin-right: 0.25rem;
                    }
                }
            }
        }
    }
</style> 