<template>
    <Section
        width="lg"
        class="banner"
    >
        <div class="compare-cards">
            <div class="compare-cards__header">
                <h1 class="header-title">{{ slice.primary.compare_cards_header }}</h1>

                <SearchCards
                    :placeholder="$translate('search_box_placeholder', 'Sök efter kort')"
                    :link="false"
                    class="header-search"
                    @click="addCard"
                />
            </div>

            <div v-if="cards.length" class="compare-cards__table">
                <table>
                    <tbody>
                        <tr>
                            <td />
                            <td
                                v-for="(id, index) in cards"
                                :key="index"
                                class="card-info"
                            >
                                <nuxt-link :to="$prismic.linkResolver(getCard(id))">
                                    <PrismicImage
                                        :img="getCardData(id).card_image"
                                        w="257"
                                        h="161"
                                    />
                                </nuxt-link>

                                <span>{{ getCardData(id).title }}</span>

                                <i class="fas fa-times" @click="remove(index)" />
                            </td>
                        </tr>

                        <tr>
                            <td>{{ $translate('card_type', 'Korttyp') }}</td>
                            <DataCell
                                v-for="card in cards"
                                :key="card.id"
                                :data-header="$translate('card_type', 'Korttyp')"
                                :data="models[card].isCredit ? $translate('credit_card', 'Kreditkort') : $translate('prepaid_card', 'Prepaid')"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('payment_network', 'Betalnätverk') }}</td>
                            <DataCell
                                v-for="card in cards"
                                :key="card"
                                :data-header="$translate('payment_network', 'Betalnätverk')"
                                :data="capitalize(models[card].paymentNetwork)"
                            />
                        </tr>

                        <tr v-if="!$isGermany()">
                            <td>{{ $translate('max_credit', 'Maxkredit') }}</td>
                            <DataCell
                                v-for="card in cards"
                                :key="card"
                                :data-header="$translate('max_credit', 'Maxkredit')"
                                :data="models[card].getMaxCreditString($translate('no_max_credit', 'Ingen kreditgräns'))"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('yearly_fee', 'Årskostnad') }}</td>
                            <DataCell
                                v-for="card in cards"
                                :key="card"
                                :data-header="$translate('yearly_fee', 'Årskostnad')"
                                :data="models[card].yearlyCostHtml"
                                :show-tooltip="models[card].hasFirstYearOffer"
                                :tooltip-text="$translate('yearly_fee_tooltip')"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('effective_rate', 'Effektiv ränta') }}</td>
                            <DataCell
                                v-for="card in cards"
                                :key="card"
                                :data-header="$translate('effective_rate', 'Effektiv ränta')"
                                :data="models[card].effectiveInterestString"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('age_limit', 'Åldersgräns') }}</td>
                            <DataCell
                                v-for="card in cards"
                                :key="card"
                                :data-header="$translate('age_limit', 'Åldersgräns')"
                                :data="models[card].minAge"
                            />
                        </tr>

                        <tr v-if="!$isGermany()">
                            <td>{{ $translate('rate_free_days', 'Räntefria dagar') }}</td>
                            <DataCell
                                v-for="card in cards"
                                :key="card"
                                :data-header="$translate('rate_free_days', 'Räntefria dagar')"
                                :data="models[card].getInterestFreeDaysString()"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('currency_exchange_fee', 'Valutapåslag') }}</td>
                            <DataCell
                                v-for="card in cards"
                                :key="card"
                                :data-header="$translate('currency_exchange_fee', 'Valutapåslag')"
                                :data="models[card].getCurrencyExchangeFeeString()"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('savings_interest', 'Sparränta') }}</td>
                            <DataCell
                                v-for="card in cards"
                                :key="card"
                                :data-header="$translate('savings_interest', 'Sparränta')"
                                :data="models[card].savingsInterestString"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('card_page_requirements_header', 'Krav') }}</td>
                            <DataCellListArray
                                v-for="card in cards"
                                :key="card"
                                :data-header="$translate('card_page_requirements_header', 'Krav')"
                                :data="$getRequirements(models[card])"
                                data-type="requirement"
                                list-type="demand"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('pros', 'Fördelar') }}</td>
                            <DataCellList
                                v-for="id in cards"
                                :key="id"
                                :data-header="$translate('pros', 'Fördelar')"
                                :data="getCardData(id).pros_condensed"
                                data-type="pro"
                                list-type="pros"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('cons', 'Nackdelar') }}</td>
                            <DataCellList
                                v-for="id in cards"
                                :key="id"
                                :data-header="$translate('cons', 'Nackdelar')"
                                :data="getCardData(id).cons_condensed"
                                data-type="pro"
                                list-type="cons"
                            />
                        </tr>

                        <tr>
                            <td>{{ $translate('features', 'Egenskaper') }}</td>
                            <td
                                v-for="id in cards"
                                :key="id"
                            >
                                <div class="table-data-header increased-spacing">
                                    {{ $translate('features', 'Egenskaper') }}
                                </div>
                                <ul class="features">
                                    <li
                                        v-for="(iterator, listIndex) in getCardData(id).features.filter($filterEmptyRelations)"
                                        :key="listIndex"
                                    >
                                        <PrismicImage
                                            :img="iterator.feature.data.image"
                                            w="20"
                                            h="20"
                                        />
                                        <span>{{ iterator.feature.data.title }}</span>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>{{ $translate('interest_example', 'Ränteexempel') }}</td>
                            <td
                                v-for="(id, index) in cards"
                                :key="index"
                            >
                                <div class="table-data-header increased-spacing">
                                    {{ $translate('interest_example', 'Ränteexempel') }}
                                </div>
                                <div v-if="models[id].complianceExample">
                                    {{ models[id].complianceExample }}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td />
                            <td
                                v-for="(id, index) in cards"
                                :key="index"
                                class="button"
                            >
                                <div v-if="getCardData(id).redirect_enabled">
                                    <ButtonTarget
                                        :link="getCard(id)"
                                        placement="comparison"
                                        :text="$translate('cta_credit_card_list', 'Till ansökan')"
                                        icon="fas fa-lock white"
                                        background="green"
                                        size="sm"
                                    />
                                    <div 
                                        v-if="$isNorway()" 
                                        class="text-subtitle"
                                    >
                                        {{ $getApplySecurelyString(getCard(id)) }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p v-else class="h4">
                {{ 
                    $translate('compare_cards_empty', 'Inga kort valda. Klicka på addera knappen för att välja kort att jämföra.') 
                }}
            </p>
        </div>
    </Section>
</template>

<script>
import { capitalize } from 'lodash';

// NOTE: Currently user can add the same card multiple times. Fix this or keep as is?
import DataCell from '@/components/compare-cards/DataCell.vue';
import DataCellList from '@/components/compare-cards/DataCellList.vue';
import DataCellListArray from '@/components/compare-cards/DataCellListArray.vue';
import SearchCards from '@/components/compare-cards/SearchCards.vue';
export default {
    components: { SearchCards, DataCell, DataCellList, DataCellListArray },
    props: {
        slice: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            cards: []
        };
    },
    computed: {
        count() {
            return this.cards.length;
        },
        models() {
            const models = {};
            for (let i = 0; i < this.count; i++) {
                models[this.cards[i]] = new this.$models.CreditCard(this.$store.state.cards[this.cards[i]]);
            }
            return models;
        }
    },
    mounted() {
        this.setCards();
    },
    methods: {
        remove(cardIndex) {
            this.cards.splice(cardIndex, 1);
        },
        addCard(card) {
            this.cards.push(card.id);
        },
        setCards() {
            for (let i = 0; i < this.$store.state.cardsToCompare.length; i++) {
                this.cards.push(this.$store.state.cardsToCompare[i]);
            }
            this.clear();
        },
        clear() {
            this.$store.commit('CLEAR_CARDS_TO_COMPARE');
        },
        getCard(id) {
            return this.$store.state.cards[id];
        },
        getCardData(id) {
            return this.getCard(id).data;
        },
        getStore() {
            return this.$store;
        },
        capitalize(str) {
            return capitalize(str);
        }
    }
};
</script>

<style lang="scss" scoped>
    ::v-deep .section-width-lg {
        width: 100%;
    }
    .compare-cards {
        @include flex(center,start);
        min-height: 40vh;

        &__header {
            width: 100%;
            @include flex;
            padding-bottom: 20px;
            margin-bottom: 20px;
            @include border(bottom, 10px, $pink);

            @include device(pad) {
                justify-content: space-between;
            }

            .header-title {
                margin-bottom: 10px;
                width: 100%;
                text-align: center;

                @include device(pad) {
                    width: auto;
                    margin-left: 30px;
                    margin-bottom: 0;
                }
            }
        }

        &__table {
            width: 100%;
            overflow-x: auto;

            table {
                width: 100%;

                tr {
                    border-collapse: collapse;

                    &:nth-child(even) {
                        td { background: #f2f2f2; }
                    }

                    td {
                        vertical-align: top;
                        font-size: 14px;
                        border-right: 4px solid $white;
                        border-bottom: 4px solid $white;
                        background: $white;
                        padding: 10px 20px;
                        font-family: $fontText;
                        min-width: 200px;
                        max-width:400px;

                        @include device(pad) {
                            min-width: 250px;
                        }

                        &:first-child {
                            font-family: $fontHeader;
                            min-width: 100px;
                            position: sticky;
                            background: $lightpink;
                            left: 0;
                            z-index: 1;
                            display: none;

                            @include device(pad) {
                                display: table-cell;
                                min-width: 150px;
                            }
                        }

                        &:last-child { border-right: 0; }
                        ::v-deep .table-data-header {
                            font-size: 12px;
                            font-weight: bold;
                            margin-bottom: 5px;
                            &.increased-spacing {
                                margin-bottom: 10px;
                            }
                            @include device(pad) {
                                display: none;
                            }
                        }

                        .text-compliance {
                            max-width:340px;
                        }
                    }

                    td.card-info {
                        position: relative;

                        img {
                            display: block;
                            margin-bottom: 20px;
                            @include size(150px, 100px);

                            @include device(desktop) {
                                @include size(257px, 161px);
                            }
                        }
                        span {
                            font-family: $fontHeader;
                            @include font-size(24px);
                            margin-right: 20px;
                        }

                        i {
                            font-size: 26px;
                            color: $red;
                            cursor: pointer;
                        }
                    }

                    td.button {
                        div { 
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }

        ul.features {
            @include grid(1, 10px);
            li {
                @include flex(start);

                img { margin-right: 10px; }
            }
        }
    }
</style>
