<template>
    <div class="wrapper">
        <div class="card-top-section">
            <div class="title-wrapper h3">
                {{ index + 1 }}.
                <h3 class="title">
                    {{ item.h3 }}
                </h3>
            </div>
            <nuxt-link :to="$prismic.linkResolver(item.card)">
                <PrismicImage
                    :img="item.card.data.card_image"
                    :alt="item.card.title"
                    class="card-image"
                    w="132"
                    h="84"
                />
            </nuxt-link>
            <div 
                v-if="$validateText(item.teaser)"    
                class="rich-text card-teaser" 
                v-html="$prismic.asHtml(item.teaser)"
            />
        </div>

        <table-data 
            v-if="dataType === 'card-data'" 
            :table-data="item.tableData"    
        />

        <pros-list 
            v-else 
            :pros="item.pros"    
        />

        <div class="footer-section">
            <div class="footer-links">
                <ButtonTarget
                    v-if="item.card.data.redirect_enabled"
                    :link="item.card"
                    :text="$translate('cta_credit_card_page', 'Ansök nu')"
                    icon="fas fa-lock white"
                    background="green"
                    size="md"
                    class="card-out-link"
                />
                <nuxt-link class="read-more-button" :to="$prismic.linkResolver(item.card)">    
                    {{ $translate('cta_read_more', 'Läs mer') }}
                    <i class="fas fa-chevrons-right fa-xs" />
                </nuxt-link>
            </div>
            <span
                v-if="item.card.data.redirect_enabled && $isNorway()"
                class="text-subtitle"
            >
                {{ $getApplySecurelyString(item.card) }}
            </span>
            <span v-if="item.model.complianceExample" class="text-compliance">
                {{ item.model.complianceExample }}
            </span>
        </div>
    </div>
</template>

<script>
import TableData from './TableData.vue';
import ProsList from './ProsList.vue';
export default {
    components: {
        TableData,
        ProsList
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        dataType: {
            type: String,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
    .wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        padding-bottom: 2rem;
        @include border(bottom);
        @include device(pad) {
            margin-top: 3rem;
            padding-bottom: 3rem;
        }

        .card-top-section {
            @include device(mobile) {
                display: flex;
                flex-direction: column;
            }

            .title-wrapper {
                display: inline-flex;
                @include device(mobile) {
                    width: 100%;
                }
                .title {
                    margin-left: 0.25em;
                }
            }
            
            .card-image {
                margin-left: 0;
                margin-top: 1rem;
                float: none;
                @include device(pad) {
                    float: right;
                    margin-left: 1rem;
                    margin-top: 0;
                }
            }
        }
        
        .footer-section {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;

            .footer-links {
                display: flex;
                flex-wrap: wrap-reverse;
                align-items: stretch;
                gap: 1rem;
                margin-bottom: 0.5rem;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-grow: 1;
                    border-radius: 3px;
                }
                
                .card-out-link {
                    border: 2px solid $green;
                    &:hover {
                        border: 2px solid $darkgreen;
                    }
                }

                .read-more-button {
                    transition: 0.3s ease-in-out;
                    text-decoration: none;
                    color: $green !important;
                    border: 2px solid $green;
                    padding: 15px 30px;
                    font-family: $fontHeader;
                    font-size: 18px;
                    &:hover {
                        color: $white !important;
                        background-color: $green;
                    }
                    @include device(pad) {
                        font-size: 20px;
                    }

                    i {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
</style>